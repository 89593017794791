#root {
  height: 100%;
  min-height: 100vh;
}

h1,
h2,
h3,
h4 {
  font-weight: 700;
}

.bold {
  font-weight: bold;
}

.flex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.wrap {
  flex-wrap: wrap;
}

.align-baseline {
  align-items: baseline;
}

.align-center {
  align-items: center;
}

.ant-layout {
  min-height: 100%;
  max-height: 100vh;
}

.ant-layout-sider-trigger {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.85);
}

h1 {
  font-size: 32px;
}

h2 {
  font-size: 24px;
}

h2.subtitle {
  color: #777777;
  font-size: 20px;
  font-weight: normal;
}

h3 {
  font-size: 20px;
}

.subtitle {
  font-size: 12px;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  opacity: 0.55;
}

.danger {
  color: #e64d3d;
}

.light {
  color: #777777;
}

.small {
  font-size: 16px;
}

.mv-1em {
  margin-top: 1em;
  margin-bottom: 1em;
}

.mr-default {
  margin-right: 1em;
}

.mr-small {
  margin-right: 0.5em;
}

.mb-default {
  margin-bottom: 1em;
}
.mb-small {
  margin-bottom: 0.5em;
}

.mv-large {
  margin-top: 24px;
  margin-bottom: 24px;
}

.mt-default {
  margin-top: 1em;
}
.mt-40 {
  margin-top: 40px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-10 {
  margin-top: 10px;
}

.mb-40 {
  margin-bottom: 40px;
}

.pd-default {
  padding: 1em;
}

.pd-30 {
  padding-top: 30px;
}

.pb-5 {
  padding-bottom: 5px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-default {
  margin-left: 1em;
}

.mb-small {
  margin-bottom: 0.5em;
}

.mb-large {
  margin-bottom: 2em;
}

.no-margin {
  margin: 0;
}

.relative {
  position: relative;
}

.text-center {
  text-align: center;
}

.block-container {
  background-color: #fff;
  padding: 2em;
  border-radius: 6px;
  min-height: 60vh;
}

.next-step-btn {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
}

.w100-per {
  width: 100%;
}

.w50-per {
  width: 50%;
}

.w60-per {
  width: 60%;
}

.w40-per {
  width: 40%;
}

.image-container {
  width: 100%;
  height: 150px;
  position: relative;
  overflow: hidden;

  & .actions {
    width: 100%;
    padding: 0.5em;
    top: 0;
    position: absolute;
    z-index: 10;
  }
}

.input-color-tooltip {
  .ant-popover-inner-content {
    padding: 0;
  }
}

.bg-grey {
  background-color: #f7f7f7;
}

.bg-white {
  background-color: white !important;
}

.border-radius {
  border-radius: 6px;
}

.padding-box {
  padding: 1em;
}

.cursor-pointer {
  cursor: pointer;
}

button.success {
  cursor: pointer;
  color: white;
  background-color: #5cb85c;

  &:hover {
    color: #5cb85c;
    border: solid 1px #5cb85c;
    background-color: white;
  }
}

.button.square {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px 20px;
  width: 120px;
  height: 120px;
  background-color: #f7f7f7;
  color: black;
  border-radius: 6px;

  &.selected {
    color: white;
    background-color: #2494d1;
  }
}

.button-white {
  background-color: white;
  color: black;
  border-color: white;
  box-shadow: 0px 2px 4px rgba(88, 88, 88, 0.16);

  &.primary {
    color: #2494d1;
  }

  &.hover {
    color: #2494d1;
  }

  &.selected {
    background-color: #2494d1;
    color: white;
    border-color: #2494d1;
  }
}

.ant-carousel .slick-dots li {
  background-color: #7bbee3;

  &.slick-active button {
    background-color: #2494d1;
  }
}

.ant-input {
  border: 1px solid #f7f7f7;
  background: #f7f7f7;
  padding: 10px 20px;
}

.input-middle {
  & .ant-input {
    padding: 4px 11px;
  }
}

.ant-select-selector,
.ant-input-affix-wrapper,
.ant-input-number {
  border: 1px solid #f7f7f7 !important;
  background: #f7f7f7 !important;

  &.bg-white {
    background-color: white !important;
  }
}

.ant-tabs-content-holder {
  overflow: auto;
}

/// FEATURES ///

.column-line {
  min-height: 40px;
  display: flex;
  justify-content: space-between;
  border-radius: 6px;
  width: 100%;
  padding: 0.5em 1em;
  align-items: center;
  cursor: pointer;
  position: relative;

  & .ellipsis {
    text-overflow: ellipsis;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
  }

  &.step {
    min-height: 20px;
    font-size: 13px;
    padding: 0.3em 1em;
  }

  &:hover {
    background-color: #f7f7f7;
  }

  &.drop-in {
    color: #fff;
    background-color: #2494d1;
  }

  &.hover-top:before {
    content: "";
    position: absolute;
    top: -4px;
    left: 0;
    right: 0;
    /* height: 0.5em; */
    border-top: 3px solid #2494d1;
    z-index: 0;
  }

  &.hover-bot:before {
    content: "";
    position: absolute;
    bottom: -4px;
    left: 0;
    right: 0;
    /* height: 0.5em; */
    border-top: 3px solid #2494d1;
    z-index: 0;
  }

  &.dropIn {
    background-color: #2494d1;
    color: white;
  }

  &.selected {
    background: #2494d1;
    color: white;
  }
}

.map-container {
  width: 100%;
  height: 100%;
  position: relative;

  &.preview {
    border: 1px solid #2494D1;
    background-color: #fff;
    border-radius: 1em;
    padding: 40px 10px;
    overflow: auto;
    margin: 50px auto;
    overflow: hidden;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 2s; /* Firefox < 16 */
    -ms-animation: fadein 2s; /* Internet Explorer */
    -o-animation: fadein 2s; /* Opera < 12.1 */
    animation: easein 2s;
    height: 90%;

    &.desktop {
      width: 90%;
    }

    &.tablet {
      width: 600px;
    }

    &.mobile {
      width: 400px;
    }

    & .drawing-msg {
      font-weight: bold;
      color: #2494D1;
      position: absolute;
      top: .5em;
      left: 50%;
      transform: translateX(-50%);
    }

    & .display-mode {
      position: absolute;
      bottom: .5em;
      left: 50%;
      transform: translateX(-50%);
    }

    & .map {
      border: 1px solid #000;
      overflow: auto;
      min-height: 100%;
    }
  }

  & .map {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}
/// Styles ///

.icon-list {
  display: grid;
  grid-template-columns: repeat(auto-fill,minmax(70px,1fr));
  grid-gap: .5em;
  grid-auto-rows: min-content;
}

.icon {
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
  box-shadow: 0 0 5px 2px #f7f7f7;
  transition: 0.3s;
  position: relative;
  border: #f4f4f4 solid 3px;
  font-size: 1.5em;
  
  &.selected {
    border: #2494D1 solid 3px;
  }

  &::before {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
  &:hover {
    box-shadow: 0 8px 16px rgb(0 0 0 / 20%);
  }
}


.style-menu-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em;
  cursor: pointer;

  &:hover {
    background-color: #f7f7f7;
  }
}

.top-menu-item {
  padding: 1em;
  background-color: #f7f7f7;
  position: sticky;
  top: 0;
  z-index: 5;
}

.minimap-preview {
  overflow: hidden;
  position: fixed;
  right: 0px;
  z-index: 10;
  bottom: 0;
  border: 2px solid white;
  box-shadow: 0px 0px 10px 0px;
  border-radius: 8px;
  margin: 1em;

  height: calc(100vh - 100px);
  width: 600px;
  transform: scale(0.5);
  transform-origin: bottom right;

  > .map {
    height: 100%;
  }

  &.large {
    height: calc(100vh - 100px);
    width: 600px;
    transform: scale(1);

    .overlay {
      width: 100%;
      transform: scale(1);
    }
  }

  & .overlay {
    position: absolute;
    padding: 1em;
    z-index: 1;
    bottom: -100%;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.85);
    color: #fff;
    width: 50%;
    transform: scale(2);
    transform-origin: bottom left;
    font-size: 0.9em;
    display: flex;
    flex-direction: row;
    & > * {
      flex: 99;
    }
    & > .btn {
      flex: 1;
      padding-left: 1em;
      cursor: pointer;
      display: inline-block;
    }
  }
  &:hover .overlay {
    bottom: 0;
  }
}

.raster-item {
  height: 100px;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0 0 5px 5px #f7f7f7;
  transition: 0.3s;
  position: relative;
  border: transparent solid 3px;

  &.selected {
    border: #2494d1 solid 3px;
  }

  &:hover {
    box-shadow: 0 0 5px 5px #d5d5d5;
  }
}
